* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  background-color: #fff;
  font-family: "Proxima Nova Rg";
  font-size: 16px;
  font-weight: normal;
  line-height: 2;
  overscroll-behavior: contain;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
a {
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
  color: #fff;
}
li {
  list-style-type: none;
}
@font-face {
  font-family: "Proxima Nova Rg";
  src: url("../Constant/fonts/ProximaNova-Regular.woff2") format("woff2"),
    url("../Constant/fonts/ProximaNova-Regular.woff2") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.popup{
top: 302px !important;
padding: 14px 0;
}
.popup-content{
 text-align: center;
}

.popup-button{
  background-color: rgb(250, 248, 248);
  width: 200px;
  height: 40px;
  font-size: 20px;
  border-radius: 10px;
  border: 1px solid gray;
  margin: 4px 0;
}
